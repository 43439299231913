@import '../../assets/styles/variables';

.render-actions {
  .actions-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }

  .root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Menlo, monospace;
    font-size: 100px;
    text-shadow: 1px 2px rgba(0, 0, 0, 0.5);
  }

  .text {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-family: Menlo, monospace;
    font-size: 1.2rem;
    text-shadow: 1px 2px rgba(0, 0, 0, 0.5);
    background-color: rgba($black, 0.5);
    border-radius: 8px;
    padding: 0.4rem;
  }

  .rec-icon {
    width: 16px;
    height: 16px;
    background: #e55226;
    border-radius: 50%;
    float: left;
    margin: 2px 8px;
    margin-left: 0;
  }

  .rec-wrapper {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: center;
    .rec-text {
      background-color: rgba($black, 0.5);
      padding: 0.5rem 2rem;
      border-radius: 8px;
      width: 70%;
      text-align: center;
      color: white;
      font-family: $font;
      font-weight: lighter;
      margin-bottom: 1rem;
    }
  }
  .button-border {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;

    .rec-button {
      background: #db4437;
      color: #db4437;
      border-radius: 50%;
      width: 64px;
      height: 64px;
      background: #db4437;
      outline: none;
      border: none;
      cursor: pointer;
      z-index: 5;
    }

    .stop-button {
      background: #db4437;
      color: #db4437;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      background: #db4437;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }

  .stop-wrapper {
    align-self: flex-end;
  }
}

.action-button {
  padding: 1rem;
  font-family: $font;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
}
