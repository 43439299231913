@import '../../assets/styles/variables';

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    display: flex;
    justify-content: center;
    height: 6rem;
    padding: 1rem;
    border-bottom: 1px solid lighten($color: $gray-1, $amount: 10%);

    img {
      height: 100%;
    }
  }

  &__main {
    flex: 1;
  }
}
