.record-container {
  width: 100%;
  height: 100%;
  background-color: blue;
  z-index: 2;
  position: absolute;
}

.error-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  text-align: center;
}
