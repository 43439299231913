@import '../assets/styles/variables';

.splash {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  picture {
    width: 70%;
    img {
      width: 100%;
    }
  }
  h3 {
    position: absolute;
    bottom: 0;
    font-weight: 500;
    font-size: 14px;

    span {
      font-weight: 700;
      font-size: 16px;
      color: $black;
    }
  }
}
