@import '../assets/styles/variables';

.shared-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 320px;
  width: 50%;
  max-width: 650px;
  margin: 0 auto;

  &__content.MuiCardContent-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 2rem;
    color: $gray-1;

    .cp-divider {
      width: 100%;
      margin: 3rem 0;
    }

    .cp-link {
      padding: 0 1rem;
      width: 90%;
      border-radius: 10px;
      background-color: $aqua-haze;
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__button {
        color: black;
      }
    }

    &:last-child {
      padding-bottom: 5rem;
    }

    input {
      text-transform: lowercase;
      text-align: center;
      font-size: 1.1rem;
    }

    .contentTab {
      margin: 0 16px;
    }
  }

  img {
    width: 550px;
  }
}

@media (max-width: $md) {
  .shared-screen {
    width: 60%;
  }
}

@media (max-width: $sm) {
  .shared-screen {
    width: 100%;
  }
}
