@import './assets/styles/variables';

body {
  color: $text;
  margin: 0;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

// This makes the box model more intuitive (inspired by Bootstrap)
* {
  box-sizing: border-box;
}

// Global Styles
.button {
  padding: 12px;
  border-radius: 3px;
  border: 1px solid transparent;
  background-color: white;
}
.button-block {
  display: block;
  width: 100%;
}
.button-danger {
  background-color: $danger-red;
}

// Atomic classes for theme, these styles can be improved
.connect {
  .theme-color {
    color: $connect-primary;
  }

  .theme-svg-icon {
    path,
    rect {
      fill: $connect-primary;
    }
  }

  .il-primary {
    fill: $connect-primary;
  }
  .il-secondary {
    fill: $connect-secondary;
  }

  .indicators .indicator.active {
    background-color: $connect-secondary;
  }
}

.is {
  .theme-color {
    color: $is-primary;
  }

  .theme-svg-icon {
    path,
    rect {
      fill: $is-primary;
    }
  }

  .il-primary {
    fill: $is-primary;
  }
  .il-secondary {
    fill: $is-secondary;
  }

  .indicators .indicator.active {
    background-color: $is-secondary;
  }
}
