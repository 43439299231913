@import '../../assets/styles/variables';

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      text-align: center;
      margin: $splash-margins;
    }

    p {
      text-align: center;
      margin: 0 $splash-margins;
    }

    ul {
      margin-top: 1.2rem;
    }

    li {
      margin-bottom: 0.8rem;
    }
  }
  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
}
