@import '../assets/styles/variables';

.welcome {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 100%;
  padding-top: 1.5rem;

  &__intro {
    margin: 0 2rem;

    p {
      margin: 2rem 0 7rem;
    }
  }
}
