@import '../assets/styles/variables';

.finished {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__image {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 3rem;
  }

  h2 {
    margin: $splash-margins;
    text-align: center;
    font-size: 1.4rem;
  }

  .loading {
    h2 {
      font-size: 1.5rem;
    }
  }

  p {
    margin: 0 $splash-margins;
    text-align: center;
    &.finished__info {
      font-size: 0.9rem;
      margin-bottom: 1.5rem;
    }
  }

  button {
    position: absolute;
    bottom: 0;
  }

  #pre-id {
    font-size: 1.7rem;
    font-weight: bold;
    text-align: center;
    margin-top: 0.4rem;
  }

  .icon-finish {
    text-align: center;
    margin: 3% 0 3%;
  }
}
